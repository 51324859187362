import React from "react"
import Layout from "./../components/layouts/Default"
import Container from "../components/layouts/container/Container"
import SectionTitle from "../components/common/SectionTitle"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Seite nicht gefunden | Döbbel Immobilien"
      desc=""
    >
      <Container className="my-8 md:my-16">
        <div className="max-w-3xl p-5 mx-auto">
          <SectionTitle
            title="Seite nicht gefunden"
            subtitle="404"
            text=""
            className="mx-auto mb-8 text-center "
          />
        </div>
      </Container>
    </Layout>
  )
}
